.success {
  .rsbContainer {
    .rsbcText, .rsbcSliderText {
      background-color: #d3ffd3;
      color: #4d784d;
    }
  }
}

.fail {
  .rsbContainer {
    .rsbcText, .rsbcSliderText {
      color: #5f2120;
    }
  }
}