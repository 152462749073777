.MuiAlert-filledSuccess {
  background-color: #d3ffd3 !important;
  color: #4d784d !important;
}

.MuiAlert-filledError {
  background-color: #f8b6b6 !important;
  color: #ca1d1b !important;
}

.MuiAlert-filledWarning {
  background-color: #ffc392 !important;
  //color: #e1751d !important;
  color: #ff9a47 !important;
}